import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Box } from "@mui/material";
import img6 from "../../../src/images/Stickers/JamieTest1.png";
import img7 from "../../../src/images/Stickers/PikeTest2.png";
import img8 from "../../../src/images//Stickers/AlimoradTest3.png";
import img9 from "../../../src/images/Stickers/GinoTest9.png";

const BSCarouselSmall = () => {
  return (
    <div>
      <Box sx={{ display: { lg: "none", md: "none", sm: "none", xs: "flex" } }}>
        <Carousel fade style={{ width: 360 }}>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 250 }}
              src={img9}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 250 }}
              src={img6}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 250 }}
              src={img8}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 250 }}
              src={img7}
              alt="Fourth slide"
            />
          </Carousel.Item>
        </Carousel>
      </Box>
    </div>
  );
};

export default BSCarouselSmall;
