import React from "react";
import Biography from "../components/Grids/Biography";
import NavigationBar from "../components/Grids/NavigationBar";
import NavigationBarMobile from "../components/Grids/NavigationBarMobile";
import ContactMeHero from "./../components/Grids/ContactMeHero";

const AboutPage = () => {
  return (
    <div>
      <NavigationBarMobile />
      <NavigationBar />
      <Biography />
      <ContactMeHero />
    </div>
  );
};

export default AboutPage;
