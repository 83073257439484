import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProjectsPage from "./pages/ProjectsPage";
import AboutPage from "./pages/AboutPage";
import ManagementPage from "./pages/ManagementPage";
import GalleryPage from "./pages/GalleryPage";

const App = () => {
  return (
    <Routes>
      <Route path="/gallery" element={<GalleryPage />}></Route>
      <Route path="/projects" element={<ProjectsPage />}></Route>
      <Route path="/management" element={<ManagementPage />}></Route>
      <Route path="/about" element={<AboutPage />}></Route>
      <Route path="/" element={<HomePage />}></Route>
    </Routes>
  );
};

export default App;
