import * as React from "react";
import { Grid, Typography, Box } from "@mui/material";
import SocialMediaStack from "../AppBar/SocialMediaStack";
import SMStackFull from "../SMStack/SMStackFull";

const ContactMeHero = () => {
  return (
    <div>
      <Grid
        container
        sx={{
          display: { lg: "flex", md: "flex", sm: "flex", xs: "flex" },
          background: "black",
          // background: "#0F3D3E",
        }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: "1em",
              mb: ".5em",
            }}
          >
            <Typography
              sx={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                color: "white",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              Contact
            </Typography>
            <Box sx={{ display: "flex", mt: ".5em", flexDirection: "column" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "1rem",
                  "&:hover": {
                    cursor: "default",
                  },
                }}
              >
                Booking / Management: COA Productions
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  fontSize: "1rem",
                  ml: ".5em",
                }}
              >
                Coapro@Verizon.net
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "1em",
              }}
            >
              <SMStackFull />
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "flex", xs: "flex" },
                color: "white",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              <p className="signature">Website by Alessandro Vannelli</p>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactMeHero;
