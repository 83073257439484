import * as React from "react";
import { Grid, Typography, Box, Container, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import img1 from "./../../images/Headshot5.jpg";
import img2 from "./../../images/LILA.jpg";

const AboutHeroNew = () => {
  let navigate = useNavigate();
  return (
    <div style={{ background: "#141b22" }}>
      <Container>
        <Grid container sx={{ display: "flex", flexDirection: "column" }}>
          {/* About for Md- */}
          <Grid
            item
            sm={12}
            xs={12}
            sx={{
              display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                mb: "1em",
                mt: "2.5em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "#141b22",
                color: "white",
              }}
            >
              <Typography
                onClick={() => navigate("/about")}
                sx={{
                  fontSize: "3rem",
                  fontWeight: "bold",
                  "&:hover": {
                    cursor: "pointer",
                    color: "orange",
                    ml: 1,
                  },
                }}
              >
                Ross Vannelli
              </Typography>
            </Box>
          </Grid>
          {/* About and Management for Lg */}
          <Grid
            container
            sx={{ display: { lg: "flex", md: "none", sm: "none", xs: "none" } }}
            spacing={15}
          >
            <Grid
              item
              lg={6}
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                mt: "4em",
                mb: "5em",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  background: "#141b22",
                  color: "white",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    onClick={() => navigate("/about")}
                    sx={{
                      display: "flex",
                      fontSize: "4rem",
                      fontWeight: "bold",
                      "&:hover": {
                        cursor: "pointer",
                        color: "orange",
                        ml: 1,
                      },
                    }}
                  >
                    Ross Vannelli
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "1.1rem",
                      fontFamily: "helvetica",
                      color: "#918ca4",
                      textAlign: "center",
                    }}
                  >
                    Award winning music and video producer, songwriter,
                    composer, editor and owner of COA Productions. For more info
                    regarding Ross, click the image below
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "2em",
                  }}
                >
                  <img
                    style={{
                      width: 420,
                      height: 300,
                      position: "static",
                    }}
                    src={img1}
                    alt=""
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                mt: "4em",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  background: "#141b22",
                  color: "white",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    onClick={() => navigate("/management")}
                    sx={{
                      display: "flex",
                      fontSize: "4rem",
                      fontWeight: "bold",
                      "&:hover": {
                        cursor: "pointer",
                        color: "orange",
                        ml: 1,
                      },
                    }}
                  >
                    Management
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      fontSize: "1.1rem",
                      fontFamily: "helvetica",
                      color: "#918ca4",
                      textAlign: "center",
                    }}
                  >
                    COA Productions represents Gino Vannelli and Jamie
                    McRoberts. For more info regarding their management, click
                    the image below
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "center", mt: "2em" }}
                >
                  <img
                    style={{
                      width: 420,
                      height: 305,
                      position: "static",
                    }}
                    src={img2}
                    alt=""
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* About Picture for Md- */}
          <Grid
            item
            sm={12}
            xs={12}
            onClick={() => navigate("/about")}
            sx={{
              display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
              justifyContent: "center",
              background: "#141b22",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <img
                style={{
                  width: 330,
                  height: 240,
                  marginBottom: 25,
                }}
                src={img1}
                alt=""
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ display: "flex", flexDirection: "column" }}>
          {/* Management for Md- */}
          <Grid
            item
            sm={12}
            xs={12}
            sx={{
              display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                mb: "1em",
                mt: "1.5em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "#141b22",
                color: "white",
              }}
            >
              <Typography
                onClick={() => navigate("/management")}
                sx={{
                  fontSize: "3rem",
                  fontWeight: "bold",
                  "&:hover": {
                    cursor: "pointer",
                    color: "orange",
                    ml: 1,
                  },
                }}
              >
                Management
              </Typography>
            </Box>
          </Grid>
          {/* Picture for Md- */}
          <Grid
            item
            sm={12}
            xs={12}
            onClick={() => navigate("/management")}
            sx={{
              display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
              justifyContent: "center",
              background: "#141b22",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                mb: "4em",
              }}
            >
              <img
                style={{
                  width: 330,
                  height: 240,
                }}
                src={img2}
                alt=""
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutHeroNew;
