import * as React from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Divider,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const GalleryContent = () => {
  let navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "750px",
        background: "#141b22",
      }}
    ></Box>
  );
};
export default GalleryContent;
