import * as React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import img1 from "./../../images/Logo-Brick.png";

const NavigationBarMobile = () => {
  let navigate = useNavigate();
  return (
    <div className="navBarHolder" style={{ background: "#141b22" }}>
      <Box
        sx={{
          display: { lg: "none", md: "none", sm: "none", xs: "flex" },
          flexDirection: "row",
          color: "white",
          justifyContent: "center",
        }}
      >
        <Box
          onClick={() => navigate("/")}
          sx={{
            display: "flex",
            justifyContent: "center",

            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <img
            style={{
              width: 90,
              height: 90,
            }}
            src={img1}
            alt=""
          />
        </Box>
      </Box>
    </div>
  );
};

export default NavigationBarMobile;
