import * as React from "react";
import { Box, Grid, Container, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./MusicProjects.css";
import img1 from "./../../images/SmallAlbum/B2BAlbum.jpg";
import img2 from "./../../images/SmallAlbum/Take6Album.jpg";
import img3 from "./../../images/SmallAlbum/CollegeDropoutAlbum.jpg";
import img4 from "./../../images/SmallAlbum/FacesAlbum.jpg";

const MusicProjects = () => {
  let navigate = useNavigate();
  return (
    <div style={{ background: "#141b22" }}>
      {/* Header for Lg + */}
      <Box
        sx={{
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          flexDirections: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2
          className="catalogueTitle"
          onClick={() => navigate("/projects")}
          sx={{
            "&:hover": {
              cursor: "pointer",
              color: "orange",
              ml: 1,
            },
          }}
        >
          Music, Film and Video Productions
        </h2>
      </Box>
      {/* Header for Md - */}
      <Box
        sx={{
          display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
          flexDirections: "column",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <h2
          className="catalogueTitleMobile"
          onClick={() => navigate("/projects")}
          sx={{
            "&:hover": {
              cursor: "pointer",
              color: "orange",
              textDecoration: "none",
            },
          }}
        >
          Catalog
        </h2>
      </Box>
      {/* Pictures for Lg + */}
      <Grid
        container
        spacing={0}
        onClick={() => navigate("/projects")}
        sx={{
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          "&:hover": {
            cursor: "pointer",
          },
          mt: "2em",
          pl: "11.5em",
          pr: "11.5em",
        }}
      >
        <Grid
          item
          lg={12}
          sx={{ display: "flex", justifyContent: "space-around", mb: 13 }}
        >
          <Box>
            <img style={{ width: 300, height: 300 }} src={img1} alt="" />
          </Box>
          <Box>
            <img style={{ width: 300, height: 300 }} src={img3} alt="" />
          </Box>
          <Box>
            <img style={{ width: 300, height: 300 }} src={img4} alt="" />
          </Box>
          <Box>
            <img style={{ width: 300, height: 300 }} src={img2} alt="" />
          </Box>
        </Grid>
      </Grid>
      {/* Pictures for Md - */}
      <Grid
        container
        spacing={0}
        onClick={() => navigate("/projects")}
        sx={{
          display: { lg: "none", md: "flex", sm: "flex", xs: "none" },
          "&:hover": {
            cursor: "pointer",
          },
          mt: "1em",
        }}
      >
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", justifyContent: "right" }}
        >
          <Box>
            <img style={{ width: 200, height: 200 }} src={img1} alt="" />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", justifyContent: "left" }}
        >
          <Box>
            <img style={{ width: 200, height: 200 }} src={img2} alt="" />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", justifyContent: "right" }}
        >
          <Box>
            <img style={{ width: 200, height: 200 }} src={img4} alt="" />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", justifyContent: "left", mb: "4em" }}
        >
          <Box>
            <img style={{ width: 200, height: 200 }} src={img3} alt="" />
          </Box>
        </Grid>
      </Grid>
      {/* Pictures for Xs */}
      <Grid
        container
        spacing={0}
        onClick={() => navigate("/projects")}
        sx={{
          display: { lg: "none", md: "none", sm: "none", xs: "flex" },
          "&:hover": {
            cursor: "pointer",
          },
          mt: "1em",
        }}
      >
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", justifyContent: "right" }}
        >
          <Box>
            <img style={{ width: 150, height: 150 }} src={img1} alt="" />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", justifyContent: "left" }}
        >
          <Box>
            <img style={{ width: 150, height: 150 }} src={img2} alt="" />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", justifyContent: "right" }}
        >
          <Box>
            <img style={{ width: 150, height: 150 }} src={img4} alt="" />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{ display: "flex", justifyContent: "left", mb: "4em" }}
        >
          <Box>
            <img style={{ width: 150, height: 150 }} src={img3} alt="" />
          </Box>
        </Grid>
      </Grid>
      <Container>
        <Divider
          sx={{
            display: { lg: "flex", md: "flex", sm: "flex", xs: "flex" },
            background: "white",
          }}
        ></Divider>
      </Container>
    </div>
  );
};

export default MusicProjects;
