import * as React from "react";
import Stack from "@mui/material/Stack";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";

const SMStackFull = () => {
  return (
    <Stack direction="row" spacing={3}>
      <Button
        href="https://www.instagram.com/coaproductions/"
        aria-label="instagram"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 40,
          color: "white",

          "&:hover": {
            cursor: "pointer",
            color: "red",
          },
        }}
      >
        <Icon icon="bi:instagram" />
      </Button>

      {/* <Button
        href="https://www.facebook.com/rossvannelli"
        aria-label="facebook"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 40,
          borderRadius: 100,
          color: "white",
          "&:hover": {
            cursor: "pointer",
            background: "#F3BE12",
          },
        }}
      >
        <Icon icon="bi:twitter" />
      </Button> */}

      <Button
        href="https://www.facebook.com/profile.php?id=100088203056506"
        aria-label="facebook"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 40,
          color: "white",

          "&:hover": {
            cursor: "pointer",
            color: "#4267B2",
          },
        }}
      >
        <Icon icon="bi:facebook" />
      </Button>

      <Button
        href="https://www.youtube.com/channel/UCIQnoNBQOZ7frmOR452Ii9g"
        aria-label="youtube"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 40,
          color: "white",

          "&:hover": {
            cursor: "pointer",
            color: "#FF0000",
          },
        }}
      >
        <Icon icon="bi:youtube" />
      </Button>
    </Stack>
  );
};

export default SMStackFull;
