import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Box } from "@mui/material";
import img6 from "../../../src/images/Stickers/JamieTest1.png";
import img7 from "../../../src/images/Stickers/PikeTest2.png";
import img8 from "../../../src/images//Stickers/AlimoradTest3.png";
import img9 from "../../../src/images/Stickers/GinoTest9.png";

const BSCarousel = () => {
  return (
    <div>
      <Box sx={{ display: { lg: "flex", md: "flex", sm: "flex", xs: "none" } }}>
        <Carousel fade style={{ width: 750 }}>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 490 }}
              src={img9}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 490 }}
              src={img6}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 490 }}
              src={img8}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 490 }}
              src={img7}
              alt="Fourth slide"
            />
          </Carousel.Item>
          {/* <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 500 }}
              src={img5}
              alt="Fifth slide"
            />
          </Carousel.Item>
        </Carousel>
      </Box>
      <Box sx={{ display: { lg: "none", md: "flex", sm: "flex", xs: "flex" } }}>
        <Carousel fade style={{ width: 600 }}>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 450 }}
              src={img1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={12000}>
            <img
              className="d-block w-100"
              style={{ height: 450 }}
              src={img2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 450 }}
              src={img3}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 450 }}
              src={img4}
              alt="Fourth slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={6500}>
            <img
              className="d-block w-100"
              style={{ height: 450 }}
              src={img5}
              alt="Fifth slide"
            />
          </Carousel.Item> */}
        </Carousel>
      </Box>
    </div>
  );
};

export default BSCarousel;
