import * as React from "react";
import { Card, Container, Box, Divider, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MusicCatalog from "./MusicCatalog";
import img1 from "./../../images/SmallAlbum/B2BAlbum.jpg";
import img2 from "./../../images/SmallAlbum/Take6Album.jpg";
import img3 from "./../../images/SmallAlbum/CollegeDropoutAlbum.jpg";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: "white",
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AllMusicSection = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{ background: "#141b22" }}>
      <Grid
        container
        sx={{
          display: "flex",
          background: "#141b22",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Typography sx={{ color: "white", mt: "1em", fontSize: "2.5rem" }}>
            <strong> Music Catalog</strong>
          </Typography>
        </Box>
      </Grid>

      {/* Picture for xs - */}
      <Box
        sx={{
          display: { lg: "none", md: "none", sm: "none", xs: "flex" },
          justifyContent: "center",
          mt: "2em",
        }}
      >
        <img
          style={{
            width: 220,
            height: 220,
          }}
          src={img3}
          alt=""
        />
      </Box>

      {/* Picture for Lg - */}
      <Card variant="basic" sx={{ background: "#141b22" }}>
        <Box sx={{ mt: "3em" }}>
          <Grid
            lg={12}
            md={12}
            item
            sx={{
              display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
              background: "#141b22",
              justifyContent: "space-evenly",
            }}
          >
            <Box
              sx={{
                display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                background: "#141b22",
                height: 220,
                width: "auto",
                ml: "1em",
              }}
            >
              <img
                style={{
                  width: 220,
                  height: 220,
                }}
                src={img1}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  ml: 3,
                  mr: 3,
                }}
              >
                <p style={{ color: "white", fontSize: "1.2rem", margin: 0 }}>
                  <strong>Brother to Brother -</strong>
                </p>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                  }}
                >
                  Gino Vannelli{" "}
                </h4>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                  }}
                >
                  Credit: Co-Producer / Arranger{" "}
                </h4>
                <Divider sx={{ background: "white" }}></Divider>
              </Box>
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                background: "#141b22",
                height: 220,
                width: "auto",
              }}
            >
              <img
                style={{
                  width: 220,
                  height: 220,
                }}
                src={img2}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  ml: 3,
                  mr: 3,
                }}
              >
                <p style={{ color: "white", fontSize: "1.2rem", margin: 0 }}>
                  <strong>Believe -</strong>
                </p>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                  }}
                >
                  Take 6{" "}
                </h4>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                  }}
                >
                  Credit: Producer{" "}
                </h4>
                <Divider sx={{ background: "white" }}></Divider>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                background: "#141b22",
                height: 220,
                width: "auto",
              }}
            >
              <img
                style={{
                  width: 220,
                  height: 220,
                }}
                src={img3}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  ml: 3,
                  mr: 3,
                }}
              >
                <p style={{ color: "white", fontSize: "1.2rem", margin: 0 }}>
                  <strong>College Dropout -</strong>
                </p>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                  }}
                >
                  Kanye West{" "}
                </h4>
                <h4
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginBottom: ".5em",
                  }}
                >
                  Credit: Song Writer{" "}
                </h4>
                <Divider sx={{ background: "white" }}></Divider>
              </Box>
            </Box>
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            mt: "2.5em",
            ml: "1.8em",
          }}
        >
          <Typography>See All</Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon sx={{ fontSize: 30 }} />
          </ExpandMore>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <MusicCatalog />
        </Collapse>
      </Card>
      <Container>
        <Divider sx={{ background: "white" }}></Divider>
      </Container>
    </div>
  );
};

export default AllMusicSection;
