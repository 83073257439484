import React from "react";
import MusicProjects from "../components/Grids/MusicProjects";
import AboutHeroNew from "../components/Grids/AboutHeroNew";
import ContactMeHero from "./../components/Grids/ContactMeHero";
import CurrentProjectsHero from "../components/Grids/CurrentProjectsHero";
import MusicSec from "../components/Grids/newMusic";
import NewsHero from "../components/Grids/newsHero";
import NewsHeroMobile from "../components/Grids/newsHeroMobile";
import TourDates from "../components/Grids/tourDates";
import YoutubeCar from "../components/Grids/YoutubeCar";
import LandingBanner from "../components/Grids/LandingBanner";
import NavigationBar from "../components/Grids/NavigationBar";
import NavigationBarMobile from "../components/Grids/NavigationBarMobile";

const HomePage = () => {
  return (
    <div>
      <NavigationBarMobile />
      <NavigationBar />
      <LandingBanner />
      <NewsHero />
      <NewsHeroMobile />
      <TourDates />
      <YoutubeCar />
      {/* <MusicSec /> */}

      {/* <CurrentProjectsHero /> */}
      <MusicProjects />
      <AboutHeroNew />

      <ContactMeHero />
    </div>
  );
};

export default HomePage;
